import { requestBase } from "../../utils/api";
import { CAT_DOMAIN_DEBUG } from "../../constants";

class SettingsApi {
  static async getInfo(host) {
    const shopDomainDebug = localStorage.getItem(CAT_DOMAIN_DEBUG);
    if (shopDomainDebug) host = shopDomainDebug;
    else if (host === "localhost:3000") host = "koci-eden.pl";
    return await requestBase
      .url(`/Info/${host}`)
      .get()
      .json();
  }
}

export default SettingsApi;
