import { CAT_TYPE_PLURAL } from "../../constants";

export const transformRoute = route => {
  let path = route.path;
  let correctedPath = path;

  switch (route.name) {
    case CAT_TYPE_PLURAL.LITTERS:
      if (!path.includes(":kind")) correctedPath = `${path}/:kind`;
      break;
    case CAT_TYPE_PLURAL.MALES:
    case CAT_TYPE_PLURAL.FEMALES:
    case CAT_TYPE_PLURAL.NEUTERS:
      if (!path.includes(":id") && !path.includes(":fullName"))
        correctedPath = `${path}/:id-:fullname`;
      break;
    default:
      break;
  }

  route.path = correctedPath;
  return route;
};

export const prepareCatRoute = (route, cat) => {
  if (cat.url) return `/${cat.url}`;
  if (route.name === CAT_TYPE_PLURAL.LITTERS)
    return route.path.replace(":kind", cat.path);

  return null;
};
