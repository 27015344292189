import React from "react"
import styled from "styled-components"
import DesktopNavigation from "./navigationBar/DesktopNavigation"
import MobileNavigation from "./navigationBar/MobileNavigation"
import MediaQuery from "react-responsive"

const NavigationContainer = styled.div`
  height: 86px;
  border-top: 1px solid #fff;
  border-bottom: 1px solid #fff;
  background-color: #f9beda;
  box-shadow: 0px 3px 5px -2px rgba(156, 156, 156, 0.8);
`
const NavigationBackground = styled.div`
  height: 58px;
  margin-top: 18px;
  border-top: 1px solid #fff;
  border-bottom: 1px solid #fff;
  background-color: #fcd6e8;
  box-shadow: 0px 3px 5px -2px rgba(128, 128, 128, 0.6);
`

const NavigationBar = ({ cats }) => {
  return (
    <NavigationContainer>
      <NavigationBackground>
        <MediaQuery minWidth={1080}>
          <DesktopNavigation cats={cats} />
        </MediaQuery>
        <MediaQuery maxWidth={1079}>
          <MobileNavigation />
        </MediaQuery>
      </NavigationBackground>
    </NavigationContainer>
  )
}

export default NavigationBar
