/* eslint-disable */
const commonNavigation = [
    {
        name: 'Home page',
        path: `/`,
        mobileHeader: true,
        icon: 'home',
        order: 1,
    },
    {
        name: 'About us',
        path: `/about-us`,
        order: 2,
    },
    {
        name: 'Contact',
        path: `/contact`,
        order: 7,
    },
]
export const mobileNavigation = [
    ...commonNavigation.map(nav => {
        const _nav = Object.assign({}, nav)
        if (nav.name === 'About us') _nav.order = 7
        if (nav.name === 'Contact') _nav.order = 8
        return _nav
    }),
    {
        name: 'Males',
        path: `/males`,
        mobileHeader: true,
        order: 3,
    },
    {
        name: 'Females',
        path: `/females`,
        mobileHeader: true,
        order: 4,
    },
    {
        name: 'Neuters',
        path: `/neuters`,
        order: 5,
    },
    {
        name: 'Litters',
        path: `/litters`,
        mobileHeader: true,
        order: 6,
    },
]
export const desktopNavigation = [
    ...commonNavigation,
    {
        name: 'Males',
        order: 3,
    },
    {
        name: 'Females',
        order: 4,
    },
    {
        name: 'Neuters',
        order: 5,
    },
    {
        name: 'Litters',
        path: `/litters/:kind`,
        order: 6,
    },
]
