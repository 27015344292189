import React from 'react'
import styled from 'styled-components'

const Title = styled.span`
    color: #c06;
    font-size: 24px;
    font-family: 'Monotype Corsiva';
`

const CatName = ({ details, hideCountry, hideName }) => {
    const fullName = `${(!hideName && details.name) || ''} ${details.nickname || ''} ${
        !hideCountry && details.countryCode ? `*${details.countryCode.toUpperCase()}` : ''
    }`
    const trimmedName = fullName.trim()
    return (
        <>
            <Title>{details.title || ''}</Title> {trimmedName}
        </>
    )
}
export default CatName

const CatRouteTitle = styled.span`
    color: red;
    ${props => props.mr && 'margin-right:5px;'}
`

export const CatRouteName = ({ details, isLitter, t }) => {
    let fullName = ''
    if (isLitter) fullName = t(details.name)
    else
        fullName = `${details.name || ''} 
  ${details.nickname || ''} 
  ${details.countryCode ? `*${details.countryCode.toUpperCase()}` : ''}`
    return (
        <>
            <CatRouteTitle mr={!!details.title}>{details.title || ''}</CatRouteTitle> {fullName.trim()}
        </>
    )
}
