import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import Backend from "i18next-xhr-backend";
import { getLanguage } from "../utils/l10n";

i18n
  .use(Backend)
  .use(initReactI18next)
  .init({
    fallbackLng: getLanguage(),
    lng: getLanguage(),
    debug: true,
    preload: ["pl", "en"],
    //ns: ["translation"],
    //backend: { crossDomain: true },
    //debug: process.env.NODE_ENV !== 'production',
    interpolation: {
      escapeValue: false
    }
    // react: {
    //   wait: true
    // }
  });
