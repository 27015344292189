import { init } from "@rematch/core";
import createLoadingPlugin from "@rematch/loading";
import { settings } from "./settings/model";

const loadingPlugin = createLoadingPlugin({ asNumber: true });

const store = init({
  plugins: [loadingPlugin],
  models: {
    settings
  }
});

export default store;
