import React, { lazy, Suspense } from "react";
import styled from "styled-components";
import { Route, Switch, Redirect } from "react-router-dom";
import routes from "./routes";
import LoadingError from "../components/shared/LoadingError";
import Header from "./defaultLayout/Header";
import NavigationBar from "./defaultLayout/NavigationBar";
import { Container, Row, Col } from "reactstrap";
import FramedCard from "../components/shared/FramedCard";

const Wrapper = styled.div`
  background-color: #eeb3d3;
`;

const DefaultLayoutContent = ({ cats }) => {
  return (
    <Wrapper>
      <Header />
      <LoadingError>
        <Suspense fallback={"..."}>
          <NavigationBar cats={cats} />
        </Suspense>
        <Container>
          <Row>
            <Col md={12}>
              <FramedCard>
                <Suspense fallback={"..."}>
                  <Switch>
                    {routes.map((route, idx) => {
                      const RouteComponent = lazy(route.loader);
                      return (
                        <Route
                          key={idx}
                          path={route.path}
                          exact={route.exact}
                          name={route.name}
                          render={props => (
                            <RouteComponent
                              {...props}
                              route={route}
                              catsList={cats[route.name.toLowerCase()]}
                            />
                          )}
                        />
                      );
                    })}
                    <Redirect to="/" />
                  </Switch>
                </Suspense>
              </FramedCard>
            </Col>
          </Row>
        </Container>
      </LoadingError>
    </Wrapper>
  );
};

export default DefaultLayoutContent;
