import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";

const NavItem = styled.div`
  position: relative;
  cursor: pointer;
`;

const StyledLink = styled(Link)`
  position: relative;
  :hover {
    text-decoration: none !important;
  }
`;

const NavigationItem = ({ hasDropdown, navProps, to, children }) => {
  if (hasDropdown || !to) return <NavItem {...navProps}>{children}</NavItem>;
  else
    return (
      <StyledLink {...navProps} to={to}>
        {children}
      </StyledLink>
    );
};

export default NavigationItem;
