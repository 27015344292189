import { getBreedingId } from "../utils/settings";
import store from "./store";

export const getApiOptions = () => {
  return {
    context: {
      breedingId: getBreedingId(),
      lang: store.getState().settings.currentLanguage
    }
  };
};
