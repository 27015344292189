import React, { useState } from "react";
import styled from "styled-components";
import { Container } from "reactstrap";
import { Link } from "react-router-dom";
import NavigationItem from "./desktopNavigation/NavigationItem";
import { prepareCatRoute } from "../../../utils/helpers/prepareRoute";
import { desktopNavigation } from "../../navigations";
import { useTranslation } from "react-i18next";
import { CatRouteName } from "../../../components/shared/CatName";

const NavigationContentBar = styled(Container)`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  padding-left: 0 !important;
  padding-right: 0 !important;
  background-color: #fdeff6;
  border-left: 1px solid #eeb7d4;
  border-right: 1px solid #eeb7d4;
`;

const NavItem = styled.div`
  color: #a64478;
  font-family: ClickerScript;
  font-size: 32px;
  padding: 5px 15px;
`;
const DropdownList = styled.div`
  ${props => (props.show ? "display:block;" : "display:none;")}
  position: absolute;
  z-index: 1;
  min-width: 300px;
  padding: 15px 0;
  background-color: #fdeff6;
  border: 1px solid #a64478;
  border-radius: 3px;
  box-shadow: 0px 3px 5px -2px rgba(128, 128, 128, 0.6);
`;
const DropdownItem = styled(Link)`
  display: flex;
  align-items: center;
  height: 30px;
  padding-left: 15px;
  padding-right: 15px;
  font-family: Verdana;
  color: #8a1352;
  font-size: 14px;
  :hover {
    background-color: #fdeff6;
    text-decoration: none !important;
    color: inherit;
  }
`;

const DesktopNavigation = ({ cats }) => {
  const [hoveredRoute, setHoveredRoute] = useState(null);
  const { t } = useTranslation();

  return (
    <NavigationContentBar>
      {desktopNavigation
        .sort((r1, r2) => r1.order - r2.order)
        .map(route => {
          const dropdown = cats[route.name.toLowerCase()];
          const navProps = {
            key: route.name,
            onMouseOver: () => {
              setHoveredRoute(route.name);
            },
            onMouseOut: () => {
              setHoveredRoute(null);
            }
          };

          return (
            <NavigationItem
              key={route.name}
              hasDropdown={!!dropdown}
              navProps={navProps}
              to={route.path}
            >
              <NavItem>{t(route.name)}</NavItem>
              <DropdownList show={hoveredRoute === route.name && !!dropdown}>
                {!!dropdown &&
                  dropdown.map(item => (
                    <DropdownItem
                      key={item.id}
                      to={prepareCatRoute(route, item)}
                    >
                      <CatRouteName
                        details={item}
                        isLitter={route.name === "Litters"}
                        t={t}
                      />
                    </DropdownItem>
                  ))}
              </DropdownList>
            </NavigationItem>
          );
        })}
    </NavigationContentBar>
  );
};

export default DesktopNavigation;
