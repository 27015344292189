import React, { PureComponent } from "react";
import { connect } from "react-redux";
import { getLanguage } from "../utils/l10n";

const withCurrentLanguage = WrappedComponent => {
  class WithCurrentLanguage extends PureComponent {
    async componentDidMount() {
      const { currentLanguage, setCurrentLanguage } = this.props;
      if (!currentLanguage) setCurrentLanguage(getLanguage());
    }

    render() {
      const { currentLanguage } = this.props;
      if (!currentLanguage) return null;
      return <WrappedComponent {...this.props} />;
    }
  }

  return connect(
    state => ({
      currentLanguage: state.settings.currentLanguage
    }),
    dispatch => ({
      setCurrentLanguage: dispatch.settings.setCurrentLanguage
    })
  )(WithCurrentLanguage);
};

export default withCurrentLanguage;
