import wretch from "wretch";
import { fetch } from "whatwg-fetch";
import retry from "../services/middlewares/retry";

export const requestBase = wretch()
  .errorType("json")
  .polyfills({
    fetch: fetch
  })
  .middlewares([
    retry({
      maxAttempts: 3
    })
  ])
  .url(process.env.REACT_APP_API)
  .defer((w, url, options) => {
    if (options.context) {
      const { breedingId, lang } = options.context;
      return w.query({ breedingId, lang });
    }
    return w;
  })
  .content(`application/json`);
