import React from 'react'
import styled from 'styled-components'
import catsImage from '../../../assets/images/footer.png'
import { useTranslation } from 'react-i18next'

const FooterContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
`
const BackgroundImage = styled.div`
    height: 300px;
`
const ContactInfo = styled.div`
    display: flex;
    align-items: center;
    position: absolute;
    z-index: 0;
    height: 300px;
    font-size: 16px;
    p {
        margin-bottom: 0 !important;
    }
`
const PositioningBlock = styled.div`
    margin-top: 40px;
    text-align: center;
    font-size: 14px;
`
const FooterText = styled.div`
    text-align: center;
    font-size: 12px;
    p {
        margin-bottom: 0 !important;
    }
`

const Footer = () => {
    const { t } = useTranslation()
    return (
        <FooterContainer>
            <BackgroundImage>
                <img src={catsImage} alt="cats" />
            </BackgroundImage>
            <ContactInfo>
                <PositioningBlock>
                    <p> Małgorzata Księżarczyk</p>
                    <p>{t('Voivodeship')} Małopolskie</p>
                    <p>({t('Near Oswiecim')})</p>
                    <p>503 154 242</p>
                    <p>
                        e-mail: <a href="mailto:kocieden@wp.pl">kocieden@wp.pl</a>
                    </p>
                </PositioningBlock>
            </ContactInfo>
            <FooterText>
                <p>
                    Copyright © 2009 - {new Date().getFullYear()} Koci Eden *PL - {t('purebred cat breeding')}
                </p>
            </FooterText>
        </FooterContainer>
    )
}

export default Footer
