import React from "react";
import styled from "styled-components";
import Footer from "./framedCard/Footer";

const FramedCardContainer = styled.div`
  position: relative;
  overflow: hidden;
  margin-top: 1px;
  padding: 15px;
  background-color: #fde3f0;
  min-height: calc(100vh - 276px - 86px);
  border: 7px solid #fff5f6;
`;
const Corner = styled.div`
  position: absolute;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background: #fff5f6;
  ${props => props.left && `left: ${props.left}px`};
  ${props => props.right && `right: ${props.right}px`};
  ${props => props.top && `top: ${props.top}px`};
  ${props => props.bottom && `bottom: ${props.bottom}px`};
`;
const Header = styled.h1`
  color: #7d556a;
  font-family: ClickerScript;
`;

const FramedCard = props => {
  return (
    <FramedCardContainer>
      <Corner left="-15" top="-15" />
      <Corner right="-15" top="-15" />
      <Corner left="-15" bottom="-15" />
      <Corner right="-15" bottom="-15" />
      <Header>{props.title}</Header>
      {props.children}
      <Footer />
    </FramedCardContainer>
  );
};

export default FramedCard;
