import React from "react";
import styled from "styled-components";
import { Container } from "reactstrap";
import logo from "../../assets/images/logo.png";
import ReactFlagsSelect from "react-flags-select";
import i18n from "i18next";
import { Image } from "../../components/shared/Image";
import withCurrentLanguage from "../../HOCs/withCurrentLanguage";

const HeaderContainer = styled.div`
  position: relative;
  height: 276px;
  overflow: hidden;
  background-color: #a6c7e8;
`;
const SettingsHeader = styled.div`
  height: 32px;
  text-align: right;
  border-bottom: 1px solid #fff;
  background-color: #eb75b1;
  .flag-select {
    margin-right: 10px;
  }
  .flag-select img {
    top: 0 !important;
  }
`;
const Logo = styled.div`
  @media (min-width: 1080px) {
    position: absolute;
    top: 0;
  }
  @media (max-width: 1079px) {
    display: flex;
    justify-content: center;
  }
  img {
    @media (max-width: 479px) {
      transform: scaleX(0.8);
    }
  }
`;

const Header = ({ currentLanguage, setCurrentLanguage }) => {
  const getLanguageCountryByCode = countryCode => {
    switch (countryCode) {
      case "PL":
        return "pl";
      case "GB":
        return "en";
      default:
        return "en";
    }
  };
  const getCountryByLanguageCode = () => {
    const languageCode = currentLanguage;
    switch (languageCode) {
      case "pl":
        return "PL";
      case "en":
        return "GB";
      default:
        return "GB";
    }
  };

  const onSelectLanguage = countryCode => {
    const languageCode = getLanguageCountryByCode(countryCode);

    setCurrentLanguage(languageCode);
    i18n.changeLanguage(languageCode);
  };

  return (
    <HeaderContainer>
      <SettingsHeader>
        <ReactFlagsSelect
          countries={["GB", "PL"]}
          customLabels={{ GB: "English", PL: "Polski" }}
          defaultCountry={getCountryByLanguageCode()}
          onSelect={onSelectLanguage}
        />
      </SettingsHeader>
      <Container>
        <Logo>
          <Image src={logo} alt="logo" />
        </Logo>
      </Container>
    </HeaderContainer>
  );
};

export default withCurrentLanguage(Header);
