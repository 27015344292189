/* eslint-disable */

const routes = [
    {
        name: 'Home page',
        path: `/`,
        exact: true,
        loader: () => import('../views/HomePage'),
    },
    {
        name: 'About us',
        path: `/about-us`,
        exact: true,
        loader: () => import('../views/AboutUsPage'),
    },
    {
        name: 'Contact',
        path: `/contact`,
        exact: true,
        loader: () => import('../views/ContactPage'),
    },
    {
        name: 'Gallery page',
        path: `/gallery/:animalId-:fullname`,
        exact: true,
        loader: () => import('../views/GalleryPage'),
    },
    {
        name: 'Males',
        path: `/males`,
        exact: true,
        loader: () => import('../views/CatListPage'),
    },
    {
        name: 'Females',
        path: `/females`,
        exact: true,
        loader: () => import('../views/CatListPage'),
    },
    {
        name: 'Neuters',
        path: `/neuters`,
        exact: true,
        loader: () => import('../views/CatListPage'),
    },
    {
        name: 'Litters',
        path: `/litters`,
        exact: true,
        loader: () => import('../views/CatListPage'),
    },
    {
        name: 'Cat',
        path: `/:id-:fullname`,
        exact: true,
        loader: () => import('../views/CatPage'),
    },
    {
        name: 'Litter',
        path: `/litters/:kind`,
        exact: true,
        loader: () => import('../views/LittersPage'),
    },
]

export default routes
