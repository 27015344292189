import React, { PureComponent } from 'react'
import styled from 'styled-components'
import { withTranslation } from 'react-i18next'
import { Container, Button } from 'reactstrap'

const RefreshButton = styled(Button)`
    margin-top: 30px;
    background-color: #f151a8;
    border-color: #f151a8;
    :hover,
    :active,
    :focus {
        background-color: #f151a8 !important;
        border-color: #f151a8 !important;
        filter: brightness(1.1);
        box-shadow: none !important;
    }
`
const ErrorWrapper = styled.div`
    min-height: calc(100vh - 276px);
    color: #9b2e68 !important;
    font-family: Clicker;
`
const PageName = styled.div`
    font-size: 26px;
    height: 52px;
    line-height: 52px;
    text-align: center;
    vertical-align: center;
    background-color: #ffc4e3;
    border-top: 1px solid white;
    border-bottom: 1px solid white;
`
const PageErrorContent = styled(Container)`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 50px;
    p {
        width: 100%;
        text-align: right;
    }
`
class LoadingError extends PureComponent {
    state = { hasError: false }

    static getDerivedStateFromError(error) {
        return { hasError: true }
    }

    componentDidCatch(error, info) {
        console.error(error, info)
    }

    render() {
        const { t } = this.props
        if (this.state.hasError) {
            return (
                <ErrorWrapper>
                    <PageName>Koci Eden</PageName>
                    <PageErrorContent>
                        <h5>{t('something went wrong')}</h5>
                        <div>
                            <RefreshButton
                                color="info"
                                onClick={() => {
                                    window.location.reload()
                                }}>
                                {t('click here to refresh page')}
                            </RefreshButton>
                        </div>
                    </PageErrorContent>
                </ErrorWrapper>
            )
        }

        return this.props.children
    }
}

export default withTranslation()(LoadingError)
