export const BREEDING_ID = "BREEDING_ID";

export const CAT_DOMAIN_DEBUG = "CAT_DOMAIN_DEBUG";

export const CAT_TYPE_PLURAL = {
  MALES: "m",
  FEMALES: "f",
  NEUTERS: "n",
  LITTERS: "Litters"
};

export const LITTER_TYPE = {
  CURRENT: "current",
  PLANNED: "planned",
  PREVIOUS: "previous"
};

export const LANGUAGE = Symbol.keyFor(Symbol.for("LANGUAGE"));
export const ALLOWED_LANGUAGE_CODES = {
  EN: "en",
  PL: "pl"
};
export const LOCALIZE_BY_LANGUAGE = {
  en: "en-GB",
  pl: "pl-PL"
};

export const ALLOWED_LANGUAGES_ARRAY = Object.values(ALLOWED_LANGUAGE_CODES);

export const STATIC_PAGES_NAMES = {
  ABOUT_US: "about-us",
  HOME: "home",
  CONTACT: "contact",
  LITTERS: "litters"
};
