import { requestBase } from "../../utils/api";
import qs from "qs";
import { getApiOptions } from "../options";

class AnimalsApi {
  static async getAnimals(gender) {
    return await requestBase
      .url(`/Animals/summary/${gender}`)
      .options(getApiOptions())
      .get()
      .json();
  }
  static async getAnimal(animalId) {
    return await requestBase
      .url(`/animals/${animalId}`)
      .options(getApiOptions())
      .get()
      .json();
  }

  static async getLitters({
    kind,
    currentPage,
    pageSize,
    sortColumnName = "id",
    sortOrder = "asc"
  }) {
    const query = qs.stringify(
      {
        currentPage,
        sortColumnName,
        sortOrder,
        pageSize,
        filter: {
          type: kind
        }
      },
      { allowDots: true }
    );
    return await requestBase
      .url(`/litters`)
      .options(getApiOptions())
      .query(query)
      .get()
      .json();
  }

  static async getGallery(animalId) {
    return await requestBase
      .url(`/Animals/albums/${animalId}`)
      .options(getApiOptions())
      .get()
      .json();
  }
}

export default AnimalsApi;
