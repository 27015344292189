import React from 'react'
import styled from 'styled-components'
import noPhoto from '../../assets/images/nophoto.jpg'

export const Image = ({ src, alt, className }) => (
    <img
        className={className}
        src={src || noPhoto}
        alt={alt || 'cat'}
        onError={(e) => {
            e.target.onerror = null
            e.target.src = noPhoto
        }}
    />
)

export const ImageContainer = styled.div`
    width: 450px;
    img {
        width: 100%;
        height: 100%;
        ${(props) => `object-fit: ${props.objectFit || 'contain'};`}
    }
    @media (max-width: 767px) {
        /* width: 300px;
    height: 250px; */
    }
    @media (max-width: 479px) {
        width: 300px;
        height: 250px;
    }
    /* override width and height to make tables responsive */
    @media (max-width: 767px) {
        ${(props) =>
            props.responsive &&
            `
        width:unset;
        height:unset;
    `}
    }
`
