import styled from "styled-components";
import ClickerScript from "../assets/fonts/ClickerScript-Regular.ttf";
import MonotypeCorsiva from "../assets/fonts/NK_Monotype.ttf";
import MongolianBaiti from "../assets/fonts/monbaiti.ttf";

export const GlobalStyle = styled.div`
 body {
  margin: 0;
  color: #4f1536;
  font-family: Arial,"Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
 }
 p{
   font-family: Arial,"Helvetica Neue", sans-serif;
  }

  .dimmer.active .dimmer-content {
    opacity: 0.2 !important;
  }

  @font-face {
    font-family: ClickerScript;
    src: url('${ClickerScript}') format('truetype');
  }
  @font-face {
    font-family: MonotypeCorsiva;
    src: url('${MonotypeCorsiva}') format('truetype');
 }
 @font-face {
    font-family: 'Mongolian Baiti';
    src: url('${MongolianBaiti}') format('truetype');
 }
 
`;
