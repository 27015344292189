import { LITTER_TYPE } from "../../constants"

export const litters = [
  {
    id: 1,
    name: "Current litters",
    path: LITTER_TYPE.CURRENT
  },
  {
    id: 2,
    name: "Planned litters",
    path: LITTER_TYPE.PLANNED
  },
  {
    id: 3,
    name: "Previous litters",
    path: LITTER_TYPE.PREVIOUS
  }
]
