import SettingsApi from "../services/settings/api";
import { BREEDING_ID } from "../constants";

export const getShopInfo = async host => {
  let response = null;
  try {
    response = await SettingsApi.getInfo(host);
    localStorage.setItem(BREEDING_ID, response.breedingId);
  } catch (e) {
    console.error("An error occured while getting shop info");
  }
  if (!response) {
    //hidePreLoaderAndShowError();
    return null;
  }
  return response;
};
