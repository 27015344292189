import "bootstrap/dist/css/bootstrap.min.css";
import "react-flags-select/css/react-flags-select.css";
import "lightgallery.js/dist/css/lightgallery.css";

import "./config/i18n";
import "./config/offlinePlugin";
import "./config/fontawesomeLib";

import { GlobalStyle } from "./config/GlobalStyle";
import { getShopInfo } from "./config/getShopInfo";

import React from "react";
import ReactDOM from "react-dom";
import { Router } from "react-router-dom";
import { Provider } from "react-redux";
import DefaultLayout from "./layout/DefaultLayout";
import store from "./services/store";
import history from "./services/history";

const bootstrap = async () => {
  const host = window.location.host.replace("www.", "");
  const { breedingId } = await getShopInfo(host);
  const rootElement = document.getElementById("root");
  if (breedingId)
    ReactDOM.render(
      <Provider store={store}>
        <GlobalStyle>
          <Router history={history}>
            <DefaultLayout />
          </Router>
        </GlobalStyle>
      </Provider>,
      rootElement
    );
  else ReactDOM.render(<div>SOMETHING WENT WRONG123</div>, rootElement);
};

bootstrap();
