import produce from "immer";
import { setLanguage, getLanguage } from "../../utils/l10n";

export const settings = {
  state: {
    currentLanguage: getLanguage()
  },
  reducers: {
    setCurrentLanguageReducer(state, payload) {
      return produce(state, draft => {
        draft.currentLanguage = payload;
      });
    }
  },
  effects: () => ({
    setCurrentLanguage(payload) {
      this.setCurrentLanguageReducer(payload);
      setLanguage(payload);
    }
  })
};
