import React, { useState } from 'react'
import styled from 'styled-components'
import { Collapse, Navbar, NavbarToggler, NavbarBrand, Nav, NavItem } from 'reactstrap'
import { Link } from 'react-router-dom'
import { mobileNavigation } from '../../navigations'
import { useTranslation } from 'react-i18next'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const StyledNavbar = styled(Navbar)`
    padding: 9px;
    svg {
        position: relative;
        bottom: 1px;
        color: #eb75b1;
        margin: 0 5px;
        font-size: 26px;
    }
`
const StyledNav = styled(Nav)`
    background: #fdeff6;
`
const StyledCollapse = styled(Collapse)`
    z-index: 1;
    margin-top: 8px;
`
const StyledNavItem = styled(NavItem)`
    padding: 10px 15px;
    text-align: center;
    color: #a64478;
    font-family: ClickerScript;
    font-size: 32px;
    a {
        color: inherit;
        text-decoration: none;
        :hover {
            color: inherit;
            text-decoration: none;
        }
    }
`
const StyledLink = styled(Link)`
    padding: 0 5px;
    text-align: center;
    color: #a64478;
    font-family: ClickerScript;
    font-size: 28px;
    text-decoration: none;
    :hover {
        color: #a64478;
        text-decoration: none;
    }
`

const MobileNavigation = () => {
    const [collapsed, setCollapsed] = useState(true)

    const { t } = useTranslation('translation')

    const toggleNavbar = () => setCollapsed(!collapsed)

    const sortedNavigation = mobileNavigation.sort((r1, r2) => r1.order - r2.order)
    const navbarNavItems = sortedNavigation.filter(nav => nav.mobileHeader)
    const hamburgerNavItems = sortedNavigation.filter(nav => !nav.mobileHeader)

    return (
        <StyledNavbar color="faded" light>
            {navbarNavItems.map(route => {
                if (route.icon)
                    return (
                        <Link key={route.name} to={route.path}>
                            <FontAwesomeIcon icon={route.icon} />
                        </Link>
                    )
                return (
                    <StyledLink key={route.name} to={route.path}>
                        {t(route.name)}
                    </StyledLink>
                )
            })}

            <NavbarBrand className="mr-auto"></NavbarBrand>
            <NavbarToggler onClick={toggleNavbar} className="mr-2" />
            <StyledCollapse isOpen={!collapsed} navbar>
                <StyledNav navbar>
                    {hamburgerNavItems.map(route => {
                        return (
                            <StyledNavItem key={route.name} onClick={toggleNavbar}>
                                <Link to={route.path}>{t(route.name)}</Link>
                            </StyledNavItem>
                        )
                    })}
                </StyledNav>
            </StyledCollapse>
        </StyledNavbar>
    )
}

export default MobileNavigation
