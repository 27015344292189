import React, { useState, useEffect, useCallback, Suspense } from "react";
import AnimalsApi from "../services/animals/api";
import { CAT_TYPE_PLURAL } from "../constants";
import { litters } from "./defaultLayout/litters";
import DefaultLayoutContent from "./DefaultLayoutContent";
import Dimmer from "../components/shared/Dimmer";

const DefaultLayout = () => {
  const [isLoading, setLoading] = useState(true);
  const [males, setMales] = useState(null);
  const [females, setFemales] = useState(null);
  const [neuters, setNeuters] = useState(null);

  const getCats = useCallback(async () => {
    setLoading(true);
    try {
      const _males = await AnimalsApi.getAnimals(CAT_TYPE_PLURAL.MALES);
      const _females = await AnimalsApi.getAnimals(CAT_TYPE_PLURAL.FEMALES);
      const _neuters = await AnimalsApi.getAnimals(CAT_TYPE_PLURAL.NEUTERS);

      setMales(_males);
      setFemales(_females);
      setNeuters(_neuters);
    } catch (e) {
      console.error(e);
    }
    setLoading(false);
  }, []);

  useEffect(() => {
    getCats();
  }, [getCats]);

  const cats = { males, females, neuters, litters };

  return (
    <Suspense fallback={"..."}>
      <Dimmer loader active={isLoading} minHeight="500px" fixed>
        <DefaultLayoutContent cats={cats} />
      </Dimmer>
    </Suspense>
  );
};

export default DefaultLayout;
