import React from "react";
import LoadingCat from "../../assets/gifs/catLoader.gif";
import styled from "styled-components";
import { useTranslation } from "react-i18next";

const StyledDimmerContent = styled.div`
  ${props => props.minHeight && `min-height:${props.minHeight};`}
`;
const Wrapper = styled.div`
  position: relative;
  img,
  p {
    ${props => (props.fixed ? `position: fixed` : `position: absolute`)};
  }
  img {
    left: calc((100% - 100px) / 2);
    ${props => (props.fixed ? `top: calc((100% - 81px) / 2)` : `top: 30px`)};
  }
  p {
    left: calc((100% - 85px) / 2);
    ${props =>
      props.fixed ? `top: calc(((100% - 24px) / 2)  + 60px)` : `top: 116px`};
  }
`;

const Loader = ({ fixed }) => {
  const { t } = useTranslation();
  return (
    <Wrapper fixed={fixed}>
      <img src={LoadingCat} alt="loading..." />
      <p>{t("loading")}...</p>
    </Wrapper>
  );
};

function DimmerContent(_ref) {
  const children = _ref.children;
  const minHeight = _ref.minHeight;

  return (
    <StyledDimmerContent minHeight={minHeight} className="dimmer-content">
      {children}
    </StyledDimmerContent>
  );
}

DimmerContent.displayName = "Dimmer.Content";

function Dimmer(_ref) {
  const children = _ref.children;
  const active = _ref.active;
  const loader = _ref.loader;
  const minHeight = _ref.minHeight;
  const fixed = _ref.fixed;
  const classes = `dimmer ${active && "active"}`;

  return React.createElement(
    "div",
    { className: classes },
    React.createElement(
      React.Fragment,
      null,
      loader && active && React.createElement(Loader, { fixed }),
      React.createElement(DimmerContent, { minHeight }, children)
    )
  );
}

Dimmer.displayName = "Dimmer";

Dimmer.Content = DimmerContent;
export default Dimmer;
